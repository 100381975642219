import * as React from "react";

import "normalize.css";

import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer";
import ContactIcon from "../components/ContactIcon";
import Container from "../components/Container";
import { AdminApi } from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";
import { InputField } from "../components/InputField";
import { StyledButtonSmallBtn } from "../components/CallToAction";

const api = new AdminApi(defaultAPIConfig());

const setPassword = ({ location }) => {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let initialRequest = false;
  const params = new URLSearchParams(location.search);
  const token = params.get("token") || "";

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Dies ist ein Pflichtfeld."),
    password2: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Die Passwörter stimmen nicht überein"
      )
      .required("Dies ist ein Pflichtfeld."),
  });

  if (token === "") {
    return null;
  }

  return (
    <LayoutRoot>
      <Header title="Account aktiviert" />
      <LayoutMain>
        <Container>
          <h1>Passwort setzen</h1>

          <p>
            {initialRequest ? (
              <>
                {errors && errors.map((error: any) => <div>{error.text}</div>)}
                {errors.length === 0 && (
                  <>
                    Sie haben Ihren Account erfolgreich aktiviert. In Kürze
                    erhalten Sie eine Mail zum Setzen Ihres Passworts.
                  </>
                )}
              </>
            ) : (
              <>
                <Formik
                  initialValues={{ password: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values: any) => {
                    const setPasswordFunction = async () => {
                      setIsLoading(true);
                      try {
                        const activateAccountResponse =
                          await api.resetcustomerpwPostEndpoint(
                            {
                              resetcustomerpwPostEndpointRequest: {
                                data: {
                                  type: "resetcustomerpw",
                                  attributes: {
                                    password: values.password,
                                    token,
                                  },
                                },
                              },
                            },
                            {
                              headers: {
                                accept: "application/vnd.api+json",
                                "content-type": "application/vnd.api+json",
                              },
                            }
                          );

                        if (activateAccountResponse) {
                          console.log("passwd set", activateAccountResponse);
                          setIsLoading(false);
                          initialRequest = true;
                          window.location.replace(`/`);
                        }
                      } catch (error) {
                        const err: any = [];
                        err.push({
                          text: "Es gab einen Fehler beim Setzen des Passworts. Bitte melden Sie sich bei info@rentir.ch",
                        });
                        setErrors(err);
                        setIsLoading(false);
                        initialRequest = true;
                      }
                      setIsLoading(false);
                      setErrors([]);
                    };

                    setPasswordFunction();
                  }}
                >
                  <>
                    <div style={{ width: "100%" }}>
                      {errors &&
                        errors.map((error: any) => <div>{error.text}</div>)}
                      <Form style={{ width: "100%" }}>
                        <InputField
                          name="password"
                          label="Passwort*"
                          type="password"
                        />
                        <InputField
                          name="password2"
                          label="Passwort wiederholen*"
                          type="password"
                        />

                        <StyledButtonSmallBtn type="submit">
                          Passwort setzen
                        </StyledButtonSmallBtn>
                      </Form>
                    </div>
                  </>
                </Formik>
              </>
            )}
          </p>
        </Container>
      </LayoutMain>
      <Footer />
      <ContactIcon />
    </LayoutRoot>
  );
};

export default setPassword;
